/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";

//import './table.css';
import "./filtering.css";

import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";
import { useParams } from "react-router-dom";
// import { CheckRounded } from "@material-ui/icons";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Permission = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);

  const [permission, setPermission] = useState([]);

  const [loader, setLoader] = useState(false);
  const token = useSelector((state) => state.auth.auth.idToken);

  const GetPermission = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}admin/permissions/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setData(response?.data);
        const temp = response?.data?.permissions
          ?.filter((e) => e?.is_assigned === 1)
          ?.map((e) => e?.id.toString());
        setPermission(temp);
        if (response?.data.status === true) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetPermission();
  }, []);

  console.log(permission, "0>>>>>>>>>>>>>");

  const handleChange = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    if (checked) {
      setPermission([...permission, id]);
    } else {
      setPermission(permission.filter((e) => e !== id));
    }
  };

  const handlePermission = () => {
    setLoader(true);
    const fromdata = new FormData();
    permission?.map((e, i) => fromdata.append(`permissions[${i}]`, +e));

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}admin/permissions/${id}`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.status === 200) {
          setLoader(false);
          GetPermission();

          Swal.fire({
            showCloseButton: true,
            toast: true,
            icon: "success",
            title: "Permissions assigned successfully",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Permission</h4>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <div className="container">
              <div className="row">
                <div className="d-flex mb-4">
                  <div className="col-md-6 md-5">
                    <h5>{`Employee Name : ${data?.subadmin?.fname} ${data?.subadmin?.fname}`}</h5>
                  </div>

                  <div className="col-md-6 md-5">
                    <h5>Employee Email : {data?.subadmin?.email}</h5>
                  </div>
                </div>
                {data?.permissions?.map((e) => {
                  return (
                    <>
                      <div className="col-md-6 md-3 d-flex">
                        <p className="col-md-3 md-3">{e?.model}</p>

                        <div className="col-md-3 md-3">
                          {" "}
                          <input
                            defaultChecked={e?.is_assigned ? true : false}
                            className="form-check-input"
                            type="checkbox"
                            value={e?.id}
                            id={e?.id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {e?.permission}
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <button
                onClick={() => handlePermission()}
                className={`btn btn-primary btn-sm mt-3 `}
              >
                Allow Permission{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Permission;
