import moment from "moment";
import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
///Images

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../services/AxiosInstance";

const Reviews = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const GetReviews = () => {
    setLoader(true);

    axiosInstance
      .get(`/reviews`)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data.data);
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const deleteReviews = (id) => {
    setLoader(true);

    axiosInstance
      .delete(`reviews/${id}`)
      .then(function (response) {
        console.log(response);
        if (response.data.status) {
          setLoader(false);
          toast.success("Review Deleted");
          GetReviews();
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const changeReview = (id) => {
    setLoader(true);

    axiosInstance
      .put(`admin/user_reviews/${id}/approve_deny`)
      .then(function (response) {
        const { response: message, status, error } = response.data;
        if (status) {
          setLoader(false);
          toast.success(message);
          GetReviews();
        } else toast.error(error);
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    GetReviews();
  }, []);
  console.log(data, "<====Data");
  return (
    <>
	{loader&&<div className="loader"/>}
      <Tab.Container defaultActiveKey="All">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header d-md-flex d-block p-0">
                <div className="card-tabs mt-3 mt-sm-0 mb-sm-0 mb-3">
                  <Nav
                    as="ul"
                    className="nav nav-tabs shadow-none"
                    role="tablist"
                  >
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link className="nav-link" eventKey="All">
                        All Reviews
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link className="nav-link" eventKey="Published">
                        Published
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="card-body pb-0">
                <Tab.Content className="tab-content">
                  <Tab.Pane eventKey="All">
                    {data.map((data, index) => (
                      <div
                        className="row align-items-center customer-review-list"
                        key={index}
                      >
                        <div className="col-xl-3 col-lg-4 mb-xl-0 mb-3">
                          <div className="review-bx">
                            <div>
                              {/* <span className="text-primary fs-16">
            #EMP-00025
          </span> */}
                              <h4 className="mt-1 fs-20 font-w600">
                                <Link className="text-black" to={"#"}>
                                  {data.fname} {data.lname}
                                </Link>
                              </h4>
                              <span className="fs-12">
                                Posted on{" "}
                                {moment(data.updated_at).format(
                                  "DDMMM YYYY, h:mm A"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-xxl-6">
                          <ul className="star-review mb-2">
                            {[...Array(5)].map((_, starIndex) => (
                              <li key={starIndex}>
                                <i
                                  className={`fas fa-star ${
                                    starIndex < data.rating ? "orange" : ""
                                  }`}
                                ></i>
                              </li>
                            ))}
                          </ul>
                          <p className="mb-0">{data.review}</p>
                        </div>
                        <div className="col-xl-3 text-end col-xxl-2 action-btn">
                          {data.status === 0 && (
                            <Link
                              to={"#"}
                              onClick={() => changeReview(data?.id)}
                              className="review-icon rounded-circle btn-success me-3"
                            >
                              <i className="far fa-check-circle"></i>
                            </Link>
                          )}
                          <Link
                            onClick={() => deleteReviews(data?.id)}
                            className="review-icon rounded-circle btn-danger"
                          >
                            <i className="far fa-times-circle"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Published">
                    {data
                      .filter((s) => s.status === 1)
                      .map((data, index) => (
                        <div
                          className="row align-items-center customer-review-list"
                          key={index}
                        >
                          <div className="col-xl-3 col-lg-4 mb-xl-0 mb-3">
                            <div className="review-bx">
                              <div>
                                {/* <span className="text-primary fs-16">
            #EMP-00025
          </span> */}
                                <h4 className="mt-1 fs-20 font-w600">
                                  <Link className="text-black" to={"#"}>
                                    {data.fname} {data.lname}
                                  </Link>
                                </h4>
                                <span className="fs-12">
                                  Posted on{" "}
                                  {moment(data.updated_at).format(
                                    "DDMMM YYYY, h:mm A"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-xxl-6">
                            <ul className="star-review mb-2">
                              {[...Array(5)].map((_, starIndex) => (
                                <li key={starIndex}>
                                  <i
                                    className={`fas fa-star ${
                                      starIndex < data.rating ? "orange" : ""
                                    }`}
                                  ></i>
                                </li>
                              ))}
                            </ul>
                            <p className="mb-0">{data.review}</p>
                          </div>
                          <div className="col-xl-3 text-end col-xxl-2 action-btn">
                            <Link
                              onClick={() => deleteReviews(data?.id)}
                              className="review-icon rounded-circle btn-danger"
                            >
                              <i className="far fa-times-circle"></i>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-12">
            <div className="d-sm-flex d-block align-items-center justify-content-between">
              <h4 className="mb-sm-0 mb-3">Showing 1 to 5 of 5 entries</h4>
              <nav>
                <ul className="pagination">
                  <li className="page-item page-indicator">
                    <Link to={"#"} className="page-link">
                      <i className="la la-angle-left"></i>
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link to={"#"} className="page-link">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to={"#"} className="page-link">
                      2
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to={"#"} className="page-link">
                      3
                    </Link>
                  </li>
                  <li className="page-item page-indicator">
                    <Link to={"#"} className="page-link">
                      <i className="la la-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </Tab.Container>
    </>
  );
};
export default Reviews;
