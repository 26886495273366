/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Employee = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },

    {
      Header: t("fname"),
      Footer: "fname",
      accessor: "fname",
      Filter: ColumnFilter,
    },
    {
      Header: t("lname"),
      Footer: "lname",
      accessor: "lname",
      Filter: ColumnFilter,
    },
    {
      Header: t("email"),
      Footer: "email",
      accessor: "email",
      Filter: ColumnFilter,
    },
    {
      Header:t("image"),
      Footer: "Image",
      accessor: "image",
      Cell: ({ row }) => {
        return (
          <>
            <img
              src={row.original.image}
              className="rounded"
              width={55}
              height={50}
            />
          </>
        );
      },
    },
    {
      Header: t("action"),
      Footer: "Action",

      Cell: ({ row }) => {
        const onDelete = (id) => {
          setLoader(true);
          var config = {
            method: "delete",
            url: `${Baseurl.baseurl}admin/subadmins/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              console.log(response, "response");
              if (response?.status === 200) {
                setLoader(false);
                GetEmployee();

                Swal.fire({
                  showCloseButton: true,
                  toast: true,
                  icon: "success",
                  title: "Deleted SuccessFully",
                  animation: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };

        return (
          <>
            <div className="d-flex">
              <button
                onClick={() => onDelete(row.original.id)}
                className={`btn btn-outline-danger `}
                style={{ borderRadius: "50%" }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                onClick={() => onEdit(row.original)}
                className={`btn btn-outline-success btn-sm `}
                style={{ borderRadius: "50%" }}
              >
                <i className="fa fa-pen" />
              </button>
              <button
                onClick={() => navigate(`permission/${row.original.id}`)}
                className={`btn btn-outline-primary btn-sm `}
                style={{ borderRadius: "50%" }}
              >
                <i class="fa fa-ellipsis-v" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const [data, setData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const token = useSelector((state) => state.auth.auth.idToken);

  const [showData, setShowData] = useState(false);
  const [showDataEdit, setShowDataEdit] = useState(false);
  const [img, setImg] = useState("");
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);
  const onShowDataEdit = () => setShowDataEdit(true);
  const onHideDataEdit = () => setShowDataEdit(false);

  const GetEmployee = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}admin/subadmins`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Employee");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetEmployee();
  }, []);

  const Api_Data = [
    { name: "fname", state: "fname" },
    { name: "lname", state: "lname" },
    { name: "email", state: "email" },
    { name: "password", state: "password" },
  ];

  const initialState = Api_Data.reduce((acc, { name, state }) => {
    acc[state] = ""; // Set initial state value as empty string, you can change it as needed
    return acc;
  }, {});

  const [userID, setUserID] = useState("");
  const [api, setApi] = useState(initialState);

  const onEdit = (values) => {
    const { fname, lname, email, id } = values;
    setUserID(id);
    onShowDataEdit();
    const data = {
      fname,
      lname,
      email,
      password: "",
    };
    setApi(data);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setApi((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };
      console.log(updatedState, "====>>>>"); // Console log the updated state
      return updatedState;
    });
  };

  const AddData = () => {
    setLoader(true);
    const fromdata = new FormData();
    Api_Data?.map((e) => fromdata.append(e.state, api[e.state]));
    fromdata.append("image", img[0]);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}admin/subadmins`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response.data.status === true) {
          setLoader(false);
          Swal.fire({
            icon: "success",
            title: response?.data?.response,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setApi(initialState);
          GetEmployee();
          setShowData(false);
          setImg("");
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: response?.data?.error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const handleEdit = () => {
    setLoader(true);
    const fromdata = new URLSearchParams();
    Api_Data?.map((e) => fromdata.append(e.state, api[e.state]));
    var config = {
      method: "put",
      url: `${Baseurl.baseurl}admin/subadmins/${userID}`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.status === 200) {
          setLoader(false);
          GetEmployee();
          setApi(initialState);

          Swal.fire({
            showCloseButton: true,
            toast: true,
            icon: "success",
            title: "Edit SuccessFully",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          onHideDataEdit();
        } else {
          onHideDataEdit();
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">{t('employee')}</h4>

            <button className="btn btn-primary ms-2" onClick={onShowData}>
              {t('add_employee')} <i className="fa fa-plus" />
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {t('previous')}
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {t('next')}
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showData} onHide={() => setShowData(false)} size="md">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">{t('add_Employee')}</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {Api_Data?.map((e) => {
                console.log(api[e?.state], "--------------->>>>>>");
                return (
                  <>
                    <div className="col-md-6 mb-3">
                      <label htmlFor={e.state} className="text-capitalize">
                        {e.name.replace(/_/g, " ")}
                      </label>{" "}
                      <input
                        key={e.name}
                        type={"text"}
                        name={e.state}
                        value={api[e.state]}
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                );
              })}
              <div className="col-md-6">
                <input
                  type="file"
                  className="form-control "
                  onChange={(e) => setImg(e.target.files)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
           {t('close')}
          </div>

          <div className="btn btn-outline-success" onClick={AddData}>
            {t('sumbit')}
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        show={showDataEdit}
        onHide={() => setShowDataEdit(false)}
        size="md"
      >
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">{t('edit_employee')}</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {Api_Data?.map((e) => {
                return (
                  <>
                    <div className="col-md-6 mb-3">
                      <label htmlFor={e.state} className="text-capitalize">
                        {e.name.replace(/_/g, " ")}
                      </label>{" "}
                      <input
                        key={e.name}
                        type={"text"}
                        name={e.state}
                        value={api[e.state]}
                        className="form-control"
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideDataEdit}>
            {t('close')}
          </div>

          <div className="btn btn-outline-success" onClick={handleEdit}>
           {t('update')}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Employee;
