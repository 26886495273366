/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Care_Homes = () => {
  const { t } = useTranslation();
  const keys = [
    "id",
    "establishment",
    "address",
    "town",
    "telephone",
    "email",
    "license",
    "license_status",
    "type",
    "sub_type",
    "contact_person",
    "license_type",
    "license_expiration",
    "capacity",
  ];

  const COLUMNS = [
    ...keys.map((key) => ({
      Header: t(key).replace(/_/g, " "),
      Footer: key,
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Documents",
      Footer: "Documents",
      accessor: "media",
      Cell: ({ row, value }) => {
        console.log(row, "newRow", value);
        const [docs, setDocs] = useState(false);
        const onShowdocs = () => setDocs(true);
        const onHidedocs = () => setDocs(false);
        const approve_docs = (id) => {
          axiosInstance
            .post(`admin/approve_blueprint/${id}`)
            .then((response) => {
              console.log(response);

              if (response.data.status === true) {
                toast.success(response.data.response);
                GetCarehomes();
              } else {
                toast.error(response.data.error);
              }
            })
            .catch((error) => console.log(error));
        };
        const disapprove_docs = (id) => {
          axiosInstance
            .post(`admin/refuse_blueprint/${id}`)
            .then((response) => {
              console.log(response);
              if (response.data.status === true) {
                toast.success(response.data.response);
                GetCarehomes();
              } else {
                toast.error(response.data.error);
              }
            })
            .catch((error) => console.log(error));
        };
        return (
          <>
            <button className="btn btn-outline-light" onClick={onShowdocs}>
              <i className="fa fa-eye" />
            </button>
            <Modal show={docs} onHide={onHidedocs} size="lg">
              <ModalHeader>
                <h4>{t("documents")}</h4>
              </ModalHeader>
              <ModalBody>
                <div className="container">
                  <table className="table display">
                    <thead>
                      <tr>
                        <th>{t("type")}</th>
                        <th>{t("document")}</th>
                        <th>{t("status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value?.map((e) => (
                        <React.Fragment key={e.type}>
                          <tr>
                            <td>
                              <h6 className="text-capitalize">{e.type}</h6>
                            </td>
                            <td>
                              {e.type === "blueprint" ? (
                                <Link
                                  to={`/bed/${row.original.id}/${row?.original?.buildings[0]?.floors[0]?.title}/${row?.original?.buildings[0]?.floors[0]?.id}`}
                                >
                                  <img
                                    src={e.document}
                                    alt=""
                                    className="img-fluid"
                                    height={50}
                                    width={50}
                                  />
                                </Link>
                              ) : (
                                <a
                                  href={e.document}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={e.document}
                                    alt=""
                                    className="img-fluid"
                                    height={50}
                                    width={50}
                                  />
                                </a>
                              )}
                            </td>
                            <td>
                              {e.type === "blueprint" ? (
                                <div className="d-flex justify-content-evenly">
                                  {e.status === 1 ? (
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => disapprove_docs(e.id)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-success"
                                        onClick={() => approve_docs(e.id)}
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => disapprove_docs(e.id)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </>
                                  )}
                                </div>
                              ) : null}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="btn btn-outline-danger" onClick={onHidedocs}>
                  {t("close")}
                </div>
              </ModalFooter>
            </Modal>
          </>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: "image",
      Cell: ({ value, row }) => {
        return (
          <>
            <img src={value} alt="No media found" width={50} height={50} />
          </>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      id: "data",
      accessor: "status",
      Cell: ({ value, row }) => {
        const changeStatus = (id) => {
          setLoader(true);
          var config = {
            method: "post",
            url: `${Baseurl.baseurl}admin/carehome/activate/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response?.data?.status === true) {
                setLoader(false);

                GetCarehomes();
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const [checkStatus, setCheckStatus] = useState("");
        return (
          <>
            <button
              onClick={() => changeStatus(row.original.id)}
              className={`btn ${
                value === 1 ? `btn-outline-success` : `btn-outline-danger`
              } text-capitalize`}
              title={checkStatus}
              onMouseEnter={() =>
                setCheckStatus(
                  `${row.original.name} is ${
                    value === 1 ? `Active` : `Deactive`
                  }`
                )
              }
            >
              {value === 1 ? `active` : `deactive`}
            </button>
          </>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "View Buildings",
      Footer: "View Buildings",

      Cell: ({ row }) => {
        return (
          <>
            <Link
              to={`/floor/${row.original.id}/${row.original.director}`}
              className={`btn btn-outline-success btn-sm`}
            >
              <i className="fa fa-building" />
            </Link>
          </>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",

      Cell: ({ row }) => {
        const onDelete = (id) => {
          setLoader(true);
          var config = {
            method: "delete",
            url: `${Baseurl.baseurl}admin/carehomes/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              console.log(response, "response");
              if (response?.status === 200) {
                GetCarehomes();

                Swal.fire({
                  showCloseButton: true,
                  toast: true,
                  icon: "success",
                  title: "Deleted SuccessFully",
                  animation: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });
                setLoader(false);
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const onfeature = (id) => {
          setLoader(true);
          var config = {
            method: "get",
            url: `${Baseurl.baseurl}admin/carehomes/feature/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response.data.status === true) {
                GetCarehomes();
                toast.success(response.data.response);
                setLoader(false);
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const unfeature = (id) => {
          setLoader(true);
          var config = {
            method: "get",
            url: `${Baseurl.baseurl}admin/carehomes/unfeature/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response.data.status === true) {
                GetCarehomes();
                toast.success(response.data.response);
                setLoader(false);
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const Send_pass = (id) => {
          setLoader(true);
          var config = {
            method: "post",
            url: `${Baseurl.baseurl}admin/carehome/update_n_send_password/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response.data.status === true) {
                GetCarehomes();
                toast.success(response.data.response);
                setLoader(false);
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        return (
          <>
            <div className="d-flex">
              {row.original?.featured_payment_status === "paid" ? (
                row.original.is_featured === 0 ? (
                  <button
                    onClick={() => onfeature(row.original.id)}
                    className={"btn btn-outline-warning btn-sm"}
                  >
                    <i className="fa fa-star" />
                  </button>
                ) : (
                  <button
                    onClick={() => unfeature(row.original.id)}
                    className={"btn btn-warning btn-sm"}
                  >
                    <i className="fa fa-star" />
                  </button>
                )
              ) : null}

              <button
                onClick={() => onDelete(row.original.id)}
                className={`btn btn-outline-danger btn-sm`}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                onClick={() => onEdit(row.original)}
                className={`btn btn-outline-success btn-sm `}
              >
                <i className="fa fa-pen" />
              </button>
              <button
                onClick={() => Send_pass(row.original.id)}
                className={`btn btn-outline-primary btn-sm `}
                title="Send Credentials"
              >
                <i className="fa fa-lock" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const [showEditData, setShowEditData] = useState(false);
  const [carehomeID, setcarehomeID] = useState("");
  const [password, setPassword] = useState("");
  const token = useSelector((state) => state.auth.auth.idToken);
  const [showData, setShowData] = useState(false);
  const [img, setImg] = useState("");

  const onshowData = () => {
    setApi(initialState);
    setShowData(true);
  };
  const onHideData = () => {
    setApi(initialState);
    setShowData(false);
  };

  const onHideData1 = () => {
    setApi(initialState);
    setPassword("");
    setShowEditData(false);
  };

  const GetCarehomes = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}carehomes`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetCarehomes();
  }, []);

  const Api_Data = [
    { name: "Establishment", state: "establishment" },
    { name: "Address", state: "address" },
    { name: "Town", state: "town" },
    { name: "Telephone", state: "telephone" },
    { name: "Email", state: "email" },
    { name: "License", state: "license" },
    { name: "license_status", state: "license_status" },
    { name: "Type", state: "type" },
    { name: "sub_type", state: "sub_type" },
    { name: "contact_person", state: "contact_person" },
    { name: "license_type", state: "license_type" },
    { name: "license_expiration", state: "license_expiration" },
    { name: "capacity", state: "capacity" },
  ];
  const initialState = Api_Data.reduce((acc, { name, state }) => {
    acc[state] = ""; // Set initial state value as empty string, you can change it as needed
    return acc;
  }, {});

  const [api, setApi] = useState(initialState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      setPassword(value);
    } else {
      setApi((prevState) => {
        const updatedState = {
          ...prevState,
          [name]: value,
        };
        console.log(updatedState); // Console log the updated state
        return updatedState;
      });
    }
  };

  const AddData = () => {
    setLoader(true);
    const fromdata = new FormData();
    Api_Data?.map((e) => fromdata.append(e.state, api[e.state]));
    fromdata.append("image", img[0]);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}admin/carehomes`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response.data.status === true) {
          GetCarehomes();
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setShowData(false);
          setLoader(false);
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: response?.data?.error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const [xlsx, setXlsx] = useState("");

  const AddExecl = () => {
    setLoader(true);
    const fromdata = new FormData();
    fromdata.append("sheet", xlsx[0]);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}admin/carehomes/bulk`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "setNewResponse");
        if (response.data.status === true) {
          GetCarehomes();
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setShowData(false);
          setLoader(false);
        } else {
          setShowData(false);
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setLoader(false);
      });
  };

  const onEdit = (values) => {
    setShowEditData(true);
    setcarehomeID(values.id);
    const initialState1 = Api_Data?.reduce((acc, { name, state }) => {
      console.log(name, state);
      acc[state] = values[state] ? values[state] : "";
      return acc;
    }, {});
    setApi(initialState1);
  };

  const handleEdit = () => {
    setLoader(true);
    const fromdata = new URLSearchParams();
    Api_Data?.map((e) => fromdata.append(e.state, api[e.state]));
    fromdata.append("password", password);
    var config = {
      method: "put",
      url: `${Baseurl.baseurl}admin/carehomes/${carehomeID}`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.status === 200) {
          GetCarehomes();
          setApi(initialState);
          setPassword("");
          setShowEditData(false);
          Swal.fire({
            showCloseButton: true,
            toast: true,
            icon: "success",
            title: "Edit SuccessFully",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setcarehomeID("");
          setLoader(false);
        } else {
          setShowEditData(false);
          setPassword("");
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const [manullay, setManullay] = useState(false);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">{t("care_homes")}</h4>

            <button
              className="btn btn-primary ms-2"
              onClick={() => onshowData()}
            >
              {t("add_data")} <i className="fa fa-plus" />
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {t("previous")}
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {t("next")}
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showData} onHide={onHideData} size="lg">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">
                  {manullay ? "Import Data " : "Add Data Manullay "}
                </h4>
              </div>
              <div className="col-md-6">
                <div className="float-right">
                  <button
                    className=" btn btn-outline-primary w-100"
                    onClick={() => {
                      setManullay(!manullay);
                    }}
                  >
                    {manullay ? "Add Data" : "Import Data"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {!manullay ? (
                <>
                  {Api_Data?.map((e) => {
                    return (
                      <>
                        <div className="col-md-6 mb-3">
                          <label htmlFor={e.state} className="text-capitalize">
                            {t(e.name).replace(/_/g, " ")}
                          </label>{" "}
                          {e.name === "Type" ? (
                            <select
                              key={e.name}
                              name={e.state}
                              value={api[e.state]}
                              className="form-control "
                              onChange={handleInputChange}
                            >
                              <option selected hidden>
                                Select Type....
                              </option>
                              <option value={"older_adults"}>
                                Older Adults
                              </option>
                            </select>
                          ) : e.name === "sub_type" ? (
                            <select
                              key={e.name}
                              name={e.state}
                              value={api[e.state]}
                              className="form-control "
                              onChange={handleInputChange}
                            >
                              <option selected hidden>
                                Select Sub Type....
                              </option>
                              <option value={"adult_institution"}>
                                Adult Institution
                              </option>
                              <option value={"campea"}>campea</option>
                            </select>
                          ) : e.name === "license_type" ? (
                            <select
                              key={e.name}
                              name={e.state}
                              value={api[e.state]}
                              className="form-control "
                              onChange={handleInputChange}
                            >
                              <option selected hidden>
                                Select License Type....
                              </option>
                              <option value={"licensed"}>Licensed</option>
                            </select>
                          ) : (
                            <input
                              key={e.name}
                              type={
                                e.name === "license_expiration"
                                  ? "date"
                                  : e.name === "capacity"
                                  ? "number"
                                  : "text"
                              }
                              name={e.state}
                              value={api[e.state]}
                              className="form-control "
                              onChange={handleInputChange}
                            />
                          )}
                        </div>
                      </>
                    );
                  })}
                  <div className="col-md-6">
                    {t("image").replace(/_/g, " ")}
                    <input
                      type="file"
                      className="form-control "
                      onChange={(e) => setImg(e.target.files)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="">{t("upload_excel_file")}</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setXlsx(e.target.files)}
                  />

                  <p className="mt-2">
                    {t("if_you_don't_have_sample_excel_file,")}{" "}
                    <a
                      href="/SampleFile.xlsx"
                      download={"Sample File"}
                      className="text-primary fw-bold "
                    >
                      <i>{t("click_here_to_download_it")}.</i>
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            {t("close")}
          </div>

          <div
            className="btn btn-outline-success"
            onClick={!manullay ? AddData : AddExecl}
          >
            {t("sumbit")}
          </div>
        </ModalFooter>
      </Modal>
      <Modal show={showEditData} onHide={onHideData1} size="lg">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">{t("edit_carehome")}</h4>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <>
                {Api_Data?.map((e) => {
                  return (
                    <>
                      <div className="col-md-6 mb-3">
                        <label htmlFor={e.state} className="text-capitalize">
                          {t(e.name).replace(/_/g, " ")}
                        </label>{" "}
                        {e.name === "Type" ? (
                          <select
                            key={e.name}
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          >
                            <option selected hidden value={api[e.state]}>
                              {api[e.state].replace(/_/g, " ")}
                            </option>
                            <option value={"older_adults"}>Older Adults</option>
                          </select>
                        ) : e.name === "sub_type" ? (
                          <select
                            key={e.name}
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          >
                            <option selected hidden value={api[e.state]}>
                              {api[e.state].replace(/_/g, " ")}
                            </option>
                            <option value={"adult_institution"}>
                              Adult Institution
                            </option>
                            <option value={"campea"}>campea</option>
                          </select>
                        ) : e.name === "license_type" ? (
                          <select
                            key={e.name}
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          >
                            <option selected hidden value={api[e.state]}>
                              {api[e.state].replace(/_/g, " ")}
                            </option>
                            <option value={"licensed"}>License</option>
                          </select>
                        ) : (
                          <input
                            key={e.name}
                            type={
                              e.name === "license_expiration"
                                ? "date"
                                : e.name === "capacity"
                                ? "number"
                                : "text"
                            }
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
                <div className="col-md-6">
                  <label htmlFor="passsowrd" className="text-capitalize">
                    {t("new_password")}
                  </label>{" "}
                  <input
                    key="password"
                    type={"text"}
                    name="password"
                    value={password}
                    className="form-control "
                    onChange={handleInputChange}
                  />
                </div>
              </>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData1}>
            {t("close")}
          </div>

          <div className="btn btn-outline-success" onClick={handleEdit}>
            {t("update")}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Care_Homes;
