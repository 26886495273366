// sidebar button not woeking
/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { axiosInstance } from "../../../services/AxiosInstance";
import { useTranslation } from "react-i18next";
/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const [getnoti, setNoti] = useState({});
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  let scrollPosition = useScrollPosition();
  let path = window.location.pathname;
  const getNotification = () => {
    axiosInstance
      .get("is_viewed")
      .then((response) => {
        console.log(response, "noti");
        const Data = response?.data?.data;
        const status = response?.data?.status;

        if (status) {
          setNoti(Data);
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  const UploadNotification = (path) => {
    axiosInstance
      .post(`is_viewed${path}`)
      .then((response) => {
        console.log(response, "noti");
        const status = response?.data?.status;

        if (status) {
          getNotification();
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  useEffect(() => {
    getNotification();
  }, []);
  const { t } = useTranslation();
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li
            className={path === "/dashboard" || path === "/" ? "mm-active" : ""}
          >
            <Link to="/dashboard" onClick={() => UploadNotification("/users")}>
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("dashboard")}
              </span>
              <span
                className={`float-end ${
                  path !== "/dashboard" && getnoti.users !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {getnoti.users === 0 ? null : getnoti.users}
              </span>
            </Link>
          </li>
          <li className={path === "/Care_Home" ? "mm-active" : ""}>
            <Link
              to="/Care_Home"
              onClick={() => UploadNotification("/carehomes")}
            >
              <i className="fa fa-home"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("care_homes")}
              </span>
              <span
                className={`float-end ${
                  path !== "/Care_Home" && getnoti.carehomes !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {getnoti.carehomes === 0 ? null : getnoti.carehomes}
              </span>
            </Link>
          </li>
          <li className={path === "/business" ? "mm-active" : ""}>
            <Link
              to="/business"
              onClick={() => UploadNotification("/business")}
            >
              <i className="fa fa-industry"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("business")}
              </span>
              <span
                className={`float-end ${
                  path !== "/business" && getnoti.business !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {" "}
                {getnoti.business === 0 ? null : getnoti.business}
              </span>
            </Link>
          </li>
          <li className={path === "/Professional" ? "mm-active" : ""}>
            <Link
              to="/Professional"
              onClick={() => UploadNotification("/professionals")}
            >
              <i className="fa fa-university"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("professional")}
              </span>
              <span
                className={`float-end ${
                  path !== "/Professional" && getnoti.professionals !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {" "}
                {getnoti.professionals === 0 ? null : getnoti.professionals}
              </span>
            </Link>
          </li>
          <li className={path === "/Subscribtion_Plan" ? "mm-active" : ""}>
            <Link to="/Subscribtion_Plan">
              <i className="fa fa-coins"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("packages")}
              </span>
            </Link>
          </li>
          <li className={path === "/Employee" ? "mm-active" : ""}>
            <Link
              to="/Employee"
              onClick={() => UploadNotification("/subadmins")}
            >
              <i className="fa fa-user"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("employee")}
              </span>
              <span
                className={`float-end ${
                  path !== "/Employee" && getnoti.subadmins !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {" "}
                {getnoti.subadmins === 0 ? null : getnoti.subadmins}
              </span>
            </Link>
          </li>
          <li className={path === "/blog" ? "mm-active" : ""}>
            <Link to="/blog" onClick={() => UploadNotification("/blogs")}>
              <i className="fa fa-coins"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("blog")}
              </span>
              <span
                className={`float-end ${
                  path !== "/blog" && getnoti.blogs !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {" "}
                {getnoti.blogs === 0 ? null : getnoti.blogs}
              </span>
            </Link>
          </li>
          <li className={path === "/Advertisment" ? "mm-active" : ""}>
            <Link
              to="/Advertisment"
              onClick={() => UploadNotification("/advertisements")}
            >
              <i className="fa fa-coins"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("advertisment")}
              </span>
              <span
                className={`float-end ${
                  path !== "/Employee" && getnoti.advertisements !== 0
                    ? "badge bg-success"
                    : "d-none"
                }`}
              >
                {" "}
                {getnoti.advertisements === 0 ? null : getnoti.advertisements}
              </span>
            </Link>
          </li>
          <li className={path === "/reviews" ? "mm-active" : ""}>
            <Link to="/reviews" onClick={() => UploadNotification("/review")}>
              <i className="fa fa-coins"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("reviews")}
              </span>
            </Link>
          </li>
          <li className={path === "/media" ? "mm-active" : ""}>
            <Link to="/media">
              <i className="fa fa-coins"></i>
              <span className="nav-text" style={{ fontSize: 14 }}>
                {t("media")}
              </span>
            </Link>
          </li>

          {/* <li className={path === "/Data_report" ? "mm-active" : ""}>
            <Link to="/Data_report">
              <i className="fa fa-file"></i>
              <span className="nav-text" style={{ fontSize:14 }}>Data & report</span>
            </Link>
          </li>
          <li className={path === "/Financal_News" ? "mm-active" : ""}>
            <Link to="/Financal_News">
              <i className="fa fa-newspaper"></i>
              <span className="nav-text" style={{ fontSize:14 }}>Financal News</span>
            </Link>
          </li> */}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
