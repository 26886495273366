/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";
import { useTranslation } from "react-i18next";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Business = () => {
  const { t } = useTranslation();
    const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },

    {
      Header: t("busines_name"),
      Footer: "Business Name",
      accessor: "title",
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Last Name",
    //   Footer: "Last Name",
    //   accessor: "lname",
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Type",
    //   Footer: "Type",
    //   accessor: "type",
    //   Filter: ColumnFilter,
    // },
    {
      Header: t("business_email"),
      Footer: "Business Email",
      accessor: "email",
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Establishment",
    //   Footer: "Establishment",
    //   accessor: "email",
    //   Filter: ColumnFilter,
    // },
    // {
    //   Header: "Director",
    //   Footer: "Director",

    //   Cell: ({ row }) => {
    //     console.log(row.original.id, "company_details");

    //     return <>{row.original.carehome.director}</>;
    //   },
    //   Filter: ColumnFilter,
    // },
    {
      Header: t("Status"),
      Footer: "Status",
      id: "data",
      accessor: "status",
      Cell: ({ value, row }) => {
        console.log(value, row.original.id, "company_details");
        const changeStatus = (id) => {
          setLoader(true);
          var config = {
            method: "post",
            url: `${Baseurl.baseurl}admin/business/activate/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response?.data?.status === true) {
                setLoader(false);

                GetCompanies();
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const [checkStatus, setCheckStatus] = useState("");
        return (
          <>
            <button
              onClick={() => changeStatus(row.original.id)}
              className={`btn ${
                value === 1 ? `btn-outline-success` : `btn-outline-danger`
              } text-capitalize`}
              title={checkStatus}
              onMouseEnter={() =>
                setCheckStatus(
                  `${row.original.name} is ${
                    value === 1 ? `Active` : `Deactive`
                  }`
                )
              }
            >
              {value === 1 ? `active` : `deactive`}
            </button>
          </>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: t("action"),
      Footer: "Action",

      Cell: ({ row }) => {
        console.log(row.original.id, "company_details");
        const changeStatus = (id) => {
          setLoader(true);
          var config = {
            method: "delete",
            url: `${Baseurl.baseurl}admin/business/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              console.log(response);
              setLoader(false);
              GetCompanies();
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        return (
          <>
            <button
              onClick={() => changeStatus(row.original.id)}
              className={`btn btn-outline-danger`}
            >
              <i className="fa fa-trash" />
            </button>
          </>
        );
      },
      Filter: ColumnFilter,
    },
  ]; //   const data = useMemo(() => MOCK_DATA, []);
  const [columns] = useState(COLUMNS);
  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const token = useSelector((state) => state.auth.auth.idToken);
  const GetCompanies = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}admin/business`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "business");
        setData(response?.data.data);
        if (response?.data?.status === true) {
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetCompanies();
  }, []);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">{t('business')}</h4>
            {/* <button
              className="btn btn-outline-primary float-right"
              onClick={onShow}
            >
              Add Professional <i className="fa fa-plus" />
            </button> */}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table  display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {t('previous')}
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {t('next')}
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Business;
